/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use strict";
"use client";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import TopLoginLogout from "@/components/nav/TopLoginLogout";
import TaskDropdown from "@/components/tasks/TaskDropdown";
import { Tasks } from "../context/TaskContext";
import { useEffect, useState } from "react";
import { getBlob, ref } from "firebase/storage";
import { storage } from "@/libs/firebase/clientApp";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const LEADERBOARD = process.env.NEXT_PUBLIC_LEADERBOARD_URL!;


export default function Navigation() {
  const [logo, setLogo] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const toggleExpand = () => {
    setOpen((prev) => !prev);
  };
  const { event, tasks } = Tasks();

  useEffect(() => {
    async function getLogo() {
      if (event) {
        const path = `assets/${event?.logo.path}`;
        const pr = ref(storage, path);
        const blob = await getBlob(pr);
        setLogo(URL.createObjectURL(blob));
      }
    }
    getLogo();
  }, [event]);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleExpand}>
      <List>
        <ListItem disablePadding>
          <ListItemButton component="a" href={`/`}>
            <ListItemText primary="Home 🏠" />
          </ListItemButton>
        </ListItem>
        {tasks &&
          [...tasks.keys()].map((id) => {
            const task = tasks.get(id);
            if (task && !task.hidden) {
              return (
                <ListItem key={id} disablePadding>
                  <ListItemButton component="a" href={`/task/${task.id}`}>
                    <ListItemText primary={task.name} />
                  </ListItemButton>
                </ListItem>
              );
            }
          })}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            <IconButton onClick={toggleExpand} aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              sx={{
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  boxSizing: "border-box",
                },
              }}
            >
              {DrawerList}
            </SwipeableDrawer>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            <Tooltip title="Return Home">
              <Link href="/">
                {logo && event && (
                  <Box
                    component="img"
                    width={event.logo.width}
                    height={event.logo.height}
                    sx={{
                      display: {
                        xs: "none",
                        sm: "flex",
                        maxWidth: 96,
                        maxHeight: 48,
                        width: "auto",
                        height: "auto",
                      },
                      mr: 3,
                      ml: 2,
                      mt: 1,
                    }}
                    alt="Super Cloud Logo"
                    src={logo}
                  />
                )}
              </Link>
            </Tooltip>
            <Box sx={{ flexGrow: 1, maxWidth: { xs: "100%", sm: 400 } }}>
              <TaskDropdown />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
              <Tooltip title="Open the leaderboard in a new tab">
                <Button
                  href={LEADERBOARD}
                  target="_blank"
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Leaderboard
                </Button>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ mr: 2 }}>
            <TopLoginLogout />
          </Box>
        </Toolbar>
      </AppBar>
      <Offset />
    </Box>
  );
}
