/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
"use strict";
"use client";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Tasks } from "@/components/context/TaskContext";
import { useParams } from "next/navigation";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface TaskListItem {
  id: string;
  name: string;
  group?: string;
}

export default function TaskDropdown() {
  const [value, setValue] = useState<TaskListItem | undefined>({
    id: "",
    name: "",
  });
  const [taskList, setTaskList] = useState<TaskListItem[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const router = useRouter();
  const { tasks } = Tasks();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    if (tasks) {
      const tl: TaskListItem[] = [];
      for (const [k, v] of tasks) {
        if (v.hidden) {
          continue;
        }
        tl.push({
          id: k,
          name: v.name,
          group: v.group,
        });
      }
      tl.push({
        id: "bonus",
        name: "Bonus Points 🎁",
        group: "Bonus",
      });
      setTaskList(tl);
      if (!loaded) {
        setLoaded(true);
        if (params.id) {
          const v = tl.find((t) => t.id === params.id);
          if (v) {
            setValue(v);
          }
        }
      }
    }
  }, [loaded, params.id, tasks]);

  useEffect(() => {
    if (params.id && tasks) {
      const v = taskList.find((t) => t.id === params.id);
      if (v) {
        setValue(v);
      }
    }
  }, [params, taskList, tasks]);

  if (tasks && tasks?.size > 0) {
    return (
      <Autocomplete
        sx={{
          backgroundColor: "background.paper",
          mt: 0.5,
          m: 1,
        }}
        groupBy={(option) => option.group || ""}
        disablePortal
        options={taskList}
        value={value}
        multiple={false}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, newValue: TaskListItem | null) => {
          if (newValue) {
            setValue(newValue);
            if (newValue) {
              const id = newValue.id === "bonus" ? "/bonus" : `/task/${newValue.id}`;
              router.push(`${id}`);
            }
          }
        }}
        renderInput={(params) => <TextField {...params} label="Select Task" />}
      />
    );
  }
}
